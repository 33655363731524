<template>
  <div class="blogbook-word">
    <page-title>从 Word 文档导入至《{{book.title}}》</page-title>

    <template v-if="status === 0">
      <b-card>
        <h4 class="card-title">
          上传word文档
          <small>仅支持docx</small>
        </h4>
        <div class="drop-zone" style="cursor: pointer;"
             @drop="onDrop" @dragover="onDragover" @dragleave="onDragleave"
             @click="$refs.files && $refs.files.click()">
          <div class="icon mb-2">
            <fa icon="file-word"/>
          </div>
          <div>
            {{dragover ? '放开上传文件' : '点击或拖动文件到此处上传'}}
            <div class="small" v-if="accept">
              支持文件格式 {{accept.join(', ')}}
            </div>
          </div>
          <slot></slot>
          <input type="file" @change="onSelect" hidden ref="files"
                 :accept="ext">
        </div>
      </b-card>

      <b-card title="导入必读">
        <div class="tip-text">
          <div>Word内容导入时，会按照导入规则对文件进行解析，请在导入前仔细阅读导入规则，按照规则修改Word文件后，再进行导入操作。</div>
          <div style="margin-top: .6rem;">注意：doc文件请转化成docx文件</div>
          <div style="margin-top: .6rem;">具体规则如下：</div>
          <div style="text-indent: 2em;">1. 一级标题，转换成文章的主标题（没有一级标题默认文件名）</div>
          <div style="text-indent: 2em;">2. 二级标题，转换成文章的小标题</div>
          <div style="text-indent: 2em;">3. 文本内容，包含三级标题，正文，列表等，转换成文章的段落内容</div>
          <div style="text-indent: 2em;">4. 表格在导入后会去掉边框等样式，仅保留文本，转换成文章的段落内容</div>
          <div style="text-indent: 2em;">5. 形状、图表、图形等特殊元素将会被忽略</div>
        </div>
        <div class="tip-img">
          <square src="https://img.xinshu.me/upload/329207c8a2e64b3db955548c549a2282" height="auto"/>
        </div>
      </b-card>
    </template>
    <template v-else-if="status === 1">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">确定word文档</h4>
          <div class="drop-zone" style="margin-bottom: 0.75rem;">
            <div class="icon mb-2">
              <fa icon="file-word"/>
            </div>
            <div>{{files[0].name}}</div>
            <div class="small">
              {{filesize(files[0].size)}}
            </div>
          </div>
          <div class="btn btn-primary btn-block" @click="clickParse">开始解析</div>
        </div>
      </div>
    </template>
    <template v-else-if="status === 2">
      <b-card>
        <h4 class="card-title">解析word文档</h4>
        <div class="drop-zone">
          <div class="icon mb-2">
            <fa icon="spinner" spin/>
          </div>
          <div>正在解析文档内容，请稍候...</div>
        </div>
      </b-card>
    </template>

    <template v-else-if="status === 3">
      <b-card v-for="(article, index) in articles" :key="index">
        <h3>{{article.title}}</h3>
        <div v-for="(item, i) in article.paragraphs.slice(0, article.expanded ? Infinity : 10)"
             :key="i">
          <div class="preview-h2" v-if="item.elements[0].tag === 'h2'">{{item.elements[0].text}}
          </div>
          <div class="preview-p" v-else-if="item.elements[0].tag === 'p'">{{item.elements[0].text}}
          </div>
          <div class="preview-img" v-else-if="item.elements[0].tag === 'img'">
            <img :src="item.elements[0].src" alt="">
          </div>
        </div>
        <b-btn variant="link" @click="article.expanded = !article.expanded" block
               v-if="article.paragraphs.length > 10">
          {{article.expanded ? '收起全文' : '展开全文'}}
        </b-btn>
      </b-card>
      <footer class="my-3">
        <b-btn @click="restart">重新上传</b-btn>
        <b-btn class="float-right" variant="primary" @click="clickConfirm">确认导入</b-btn>
      </footer>
    </template>
    <template v-else-if="status === 4">
      <b-card>
        <h4 class="card-title">导入word文档</h4>
        <div class="drop-zone" style="margin-bottom: 0.75rem;">
          <div class="icon mb-2">
            <fa icon="spinner" spin/>
          </div>
          <div>{{'正在导入第 ' + addNum + ' 篇，请稍等...'}}</div>
        </div>
      </b-card>
    </template>
    <template v-else-if="status === 5">
      <b-card>
        <h4 class="card-title">导入结果</h4>
        <div class="drop-zone" style="margin-bottom: 0.75rem;">
          <div class="icon mb-2">
            <fa v-if="addFail" icon="times-circle"/>
            <fa v-else icon="check-circle"/>
          </div>
          <div v-if="addFail">导入失败，请检查Word并重新尝试</div>
          <div v-else>已经导入到您的作品中</div>
        </div>
        <footer class="mt-3">
          <b-btn @click="restart">{{addFail ? '重新上传' : '继续上传'}}</b-btn>
          <b-btn class="float-right" variant="primary" @click="goPreview" v-if="!addFail">
            预览作品
          </b-btn>
        </footer>
      </b-card>
    </template>
    <template v-else-if="status === 6">
      <b-card>
        <h4 class="card-title">解析word错误</h4>
        <div class="drop-zone" style="margin-bottom: 0.75rem;">
          <div class="icon mb-2">
            <fa icon="times-circle"/>
          </div>
          <div>解析错误！可能的原因：包含非常规的表格。请修改后重新上传</div>
          <div>{{error}}</div>
        </div>
        <footer class="mt-3">
          <b-btn @click="restart">重新上传</b-btn>
        </footer>
      </b-card>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import { cloneDeep, filter, map } from 'lodash'
import mammoth from 'mammoth'
import Upload from '@/models/upload'
import { addBlogbookArticle } from '@/models/blogbook'

export default {
  name: 'blogbookWord',
  title: '导入Word',
  mixins: [routeData('book')],
  data() {
    return {
      status: 0,
      dragover: false,
      accept: ['docx'],
      files: [],
      articles: [],
      uploadModel: new Upload(),
      addFail: false,
      addResult: {},
      error: '',
      addNum: 0
    }
  },
  computed: {
    ext() {
      if (this.accept && this.accept.length) {
        return this.accept.map(item => '.' + item.trim()).join(',')
      }
      return ''
    }
  },
  beforeDestroy() {
    this.uploadModel.destroy()
  },
  mounted() {
    this.$bvModal.show('tip-detail')
  },
  methods: {
    restart() {
      this.status = 0
      this.files = []
      this.articles = []
      this.addFail = false
      this.addNum = 0
    },
    goPreview() {
      if (this.addResult && this.addResult.id) {
        this.$router.push(`/books/${this.book.bookId}/months/${this.addResult.id}`)
      } else {
        this.$router.push(`/books/${this.book.bookId}`)
      }
    },
    groupArray(array, subGroupLength) {
      let index = 0
      const newArray = []

      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }

      return newArray
    },
    async clickConfirm() {
      this.status = 4
      for (let i = 0; i < this.articles.length; i++) {
        this.addNum = i + 1
        const ai = this.articles[i]
        const paragraphs = ai.paragraphs
        const data = {
          bid: this.book.id,
          title: ai.title
        }
        try {
          const addArticle = await addBlogbookArticle(data)
          const pArr = this.groupArray(paragraphs, 200)
          for (let j = 0; j < pArr.length; j++) {
            const pj = pArr[j]
            for (let k = 0; k < pj.length; k++) {
              const pjk = pj[k].elements[0]
              if (pjk.tag && pjk.tag === 'img') {
                try {
                  const imgfile = this.dataURItoBlob(pjk.src)
                  const uploadRes = await this.uploadModel.putFile(imgfile)
                  pjk.src = uploadRes.src
                  pjk.w = uploadRes.width
                  pjk.h = uploadRes.height
                } catch (err) {
                  console.error('上传图片出错', err)
                }
              }
            }
            try {
              await this.$req.post(
                `/blogbook/books/${this.book.id}/articles/${addArticle.id}/`,
                {
                  append_paragraphs: pArr[j]
                }
              )
            } catch (error) {
              console.error('导入段落错误', error)
            }
          }
        } catch (error) {
          console.error('创建文章错误', error)
        }
      }
      this.status = 5
    },
    async addArticle() {
      const data = {
        bid: this.book.id,
        articles: cloneDeep(this.articles)
      }
      try {
        this.addResult = await addBlogbookArticle(data)
      } catch (error) {
        this.addFail = true
      } finally {
        this.status = 5
      }
    },
    onSelect({target}) {
      if (target.files) {
        const files = this.checkFiles(target.files)
        if (files.length) {
          this.files = files
          this.status = 1
        }
        target.files = null
        target.value = null
      }
    },
    clickParse() {
      this.handleFileSelect(this.files[0])
    },
    async onDrop(e) {
      this.dragover = false
      e.stopPropagation()
      e.preventDefault()
      if (e.dataTransfer) {
        const items = e.dataTransfer.items
        const results = await getFiles(items)
        const suffix = results[0].name.split('.')[results[0].name.split('.').length - 1]
        if (suffix !== 'docx') {
          this.$alert.warn('不支持当前格式文件')
          this.restart()
        } else {
          if (results.length) {
            this.files = results
            this.status = 1
          }
        }
        e.dataTransfer.clearData()
      }
    },
    onDragover(e) {
      this.dragover = true
      e.stopPropagation()
      e.preventDefault()
    },
    onDragleave(e) {
      this.dragover = false
      e.stopPropagation()
      e.preventDefault()
    },
    checkFiles(files) {
      return filter(files, file => {
        const ext = file.name.split('.').reverse()[0]
        if (this.accept && this.accept.length && !this.accept.includes(ext)) {
          this.$alert.warn('不支持当前格式文件')
          return false
        }
        return true
      })
    },
    judgeImgNode(parentNode) {
      let isImg = false
      const reg = /image\/(\S*);base64/
      if (parentNode.nodeName === 'IMG') {
        const imgType = parentNode.src.match(reg)[1]
        if (imgType === 'png' || imgType === 'jpeg' || imgType === 'gif') {
          isImg = true
        }
      } else {
        const children = parentNode.childNodes
        for (let d = 0; d < children.length; d++) {
          const child = children[d]
          if (child.nodeName === 'IMG') {
            const imgType = child.src.match(reg)[1]
            if (imgType === 'png' || imgType === 'jpeg' || imgType === 'gif') {
              isImg = true
              break
            }
          }
        }
      }
      return isImg
    },
    async handleFileSelect(file) {
      try {
        this.status = 2
        const arrayBuffer = await this.readFileAsArrayBuffer(file)
        const res = await mammoth.convertToHtml({arrayBuffer})
        const html = res.value
        const parser = new DOMParser()
        const htmlDoc = parser.parseFromString(html, 'text/html')
        const articles = []
        let article = {}
        article.paragraphs = []
        for (const node of Array.from(htmlDoc.body.childNodes)) {
          if (this.judgeImgNode(node)) {
            let imgTag = []
            if (node.nodeName === 'IMG') {
              imgTag.push(node)
            } else {
              imgTag = [...node.getElementsByTagName('IMG')]
            }
            for (const img of imgTag) {
              const elements = [{tag: 'img', src: img.src}]
              article.paragraphs.push({elements})
            }
          } else {
            if (node.nodeName === 'H1') {
              if ('title' in article) {
                articles.push(article)
                article = {}
              }
              const itext = node.innerText.trim()
              if (itext !== '') {
                article = {}
                article.title = itext
                article.paragraphs = []
              }
            } else if (node.nodeName === 'H2') {
              const itext = node.innerText.trim()
              if (itext !== '') {
                const elements = [
                  {
                    tag: 'h2',
                    text: itext
                  }
                ]
                article.paragraphs.push({elements})
              }
            } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
              let txt = ''
              const lis = node.childNodes
              for (let j = 0; j < lis.length; j++) {
                txt += lis[j].innerText
                if (j !== lis.length - 1) {
                  txt += '\n'
                }
              }
              const elements = [{tag: 'p', text: txt}]
              article.paragraphs.push({elements})
            } else if (node.nodeName === 'TABLE') {
              let txt = ''
              const tbody = node.firstChild
              const trs = tbody.childNodes
              for (let j = 0; j < trs.length; j++) {
                const tds = trs[j].childNodes
                let line = `行${j + 1}: `
                for (let k = 0; k < tds.length; k++) {
                  line += tds[k].innerText
                  if (k !== tds.length - 1) {
                    line += ' ‖ '
                  }
                }
                txt += line
                if (j !== trs.length - 1) {
                  txt += '\n'
                }
              }
              const elements = [{tag: 'p', text: txt}]
              article.paragraphs.push({elements})
            } else {
              const itext = node.innerText.trim()
              if (itext !== '') {
                const elements = [{tag: 'p', text: itext}]
                article.paragraphs.push({elements})
              }
            }
          }
        }

        if ('title' in article) {
          articles.push(article)
        } else if (article.paragraphs.length > 0) {
          article.title = this.files[0].name.split('.')[0]
          articles.push(article)
        }

        this.articles = articles.map(a => {
          a.expanded = false
          return a
        })

        this.status = 3
      } catch (err) {
        this.error = err.message
        this.status = 6
      }
    },
    filesize(limit) {
      let size = ''
      if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B'
      } else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB'
      } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
      } else { // 其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
      }
      const sizestr = size + ''
      // eslint-disable-next-line no-useless-escape
      const len = sizestr.indexOf('\.')
      const dec = sizestr.substr(len + 1, 2)
      if (dec === '00') { // 当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
      }
      return sizestr
    },
    dataURItoBlob(dataURI) {
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0] // mime类型
      const byteString = atob(dataURI.split(',')[1]) // base64 解码
      const arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
      const intArray = new Uint8Array(arrayBuffer) // 创建视图

      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i)
      }
      return new Blob([intArray], {type: mimeString})
    },
    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (loadEvent) => {
          resolve(loadEvent.target.result)
        }
        reader.onerror = () => {
          reject(new Error('Read file error'))
        }
        reader.readAsArrayBuffer(file)
      })
    }
  }
}

function getFiles(items) {
  let output = []
  return Promise.all(map(items, item => {
    if (!item.isDirectory && !item.isFile) {
      item = item.webkitGetAsEntry()
    }
    return traverseFileTree(item).then(results => {
      output = output.concat(results)
    })
  })).then(() => {
    return output
  })
}

function traverseFileTree(item, path = '') {
  if (item.isFile) {
    return new Promise((resolve, reject) => {
      item.file(file => {
        file.fullPath = path + file.name
        resolve([file])
      }, reject)
    })
  }

  if (item.isDirectory) {
    return new Promise((resolve, reject) => {
      const dirReader = item.createReader()
      const results = []
      dirReader.readEntries(async entries => {
        for (let i = 0; i < entries.length; i++) {
          const files = await traverseFileTree(entries[i], path + item.name + '/')
          results.push(...files)
        }
        resolve(results)
      }, reject)
    })
  }
}
</script>

<style lang="scss" scoped>
.blogbook-word {
  max-width: 640px;
  position: relative;

  .drop-zone {
    padding: 2rem;
    padding-top: 1.5rem;
    background-color: #eee;
    text-align: center;
    transition: .3s;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon {
      font-size: 2rem;
    }
  }

  .image-box {
    width: 25%;
  }

  .detail {
    color: $primary;
    float: right;
    cursor: pointer;
  }

  .tip {
    font-size: 1rem;
    color: $primary;
    font-weight: normal;
    cursor: pointer;
  }
}

.preview-h2 {
  font-size: 17px;
  font-weight: 700;
  line-height: 2.4;
  word-break: break-all;
}

.preview-p {
  font-size: 14px;
  line-height: 1.5;
  text-indent: 2em;
  margin-bottom: 0.5rem;
  white-space: pre-line;
  word-break: break-all;
}

.preview-img {
  text-align: center;
  margin: .75rem 1rem;

  img {
    max-width: 100%;
    max-height: 15rem;
  }
}

.tip-text {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 1rem;
}
</style>
