<template>
  <div class="creation">
    <b-card class="select-bar" v-headroom>
      <b-row align-v="center">
        <b-col v-if="step === 2">
          <b-link class="text-primary" @click="back">
            <fa icon="chevron-left"/>
            返回
          </b-link>
        </b-col>
        <b-col v-if="step === 1">
          <b>导入至《{{book.title}}》</b>
        </b-col>
        <template v-if="searching">
          <b-col cols="auto">
            <b-input size="sm" placeholder="按标题筛选" v-model.trim="keywords" autofocus @change="handleSearch"/>
          </b-col>
          <b-col cols="auto" class="pl-0">
            <b-btn size="sm" @click="cancelSearch">取消</b-btn>
          </b-col>
        </template>
        <b-col cols="auto">
          <template v-if="step === 1">
            <span>已选 {{selectedSource.length}} 个</span>
            &#12288;
            <b-link @click="importSource('source')">
              确认导入
            </b-link>
          </template>
          <template v-else-if="step === 2">
            <b-link v-if="!searching" class="mr-2" @click="searching = true">
              <fa icon="search"/>
              搜索
            </b-link>
            <span class="mr-2">已选 {{selectedArticle.length}} 篇</span>
            <b-link @click="importSource('article')">
              确认导入
            </b-link>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <template v-if="step === 1">
      <b-card>
        <template #header>
          <ske width="8em" v-if="fetching && sourcePage === 1"/>
          <b v-else>来源（共 {{sourceCount || 0}} 个）</b>
        </template>
        <template v-if="fetching && sourcePage === 1">
          <div class="list">
            <div class="item-box" v-for="i in 7" :key="i">
              <ske width="1em"/>
              <ske width="10em"/>
              <ske width="6em" class="float-right"/>
            </div>
          </div>
        </template>
        <empty v-else-if="!sources.length">
          这里还没有内容哦
        </empty>
        <template v-else>
          <div class="list">
            <div class="item-box" v-for="item in sources" :key="item.id">
              <div class="article-item">
                <div class="article-number pointer" @click="selectSource(item)">
                  <div>
                    <fa icon="check-circle"
                        :class="{'text-primary': selectedSource.indexOf(item) > -1}"
                        v-if="selectedSource.indexOf(item) > -1"/>
                    <fa icon="circle" far class="text-muted" v-else/>
                  </div>
                </div>
                <div class="article-title pointer" @click="selectSource(item)">{{item.sourceName}}</div>
                <div class="text-muted text-nowrap">
                  <b-badge variant="muted">
                    <img class="icon" :src="sourceSite[item.sourceSite].icon" alt="">
                    {{sourceSite[item.sourceSite].name}}
                  </b-badge>
                </div>
                <div class="goin text-primary pointer" @click="goDetail(item)">
                  详情
                  <fa icon="arrow-right"/>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-card>
      <div class="btn-area" v-if="sources.length < sourceCount">
        <loading v-if="fetching && sourcePage !== 1" style="height: 39px;"/>
        <b-btn block @click="nextSource" v-else>共 {{sourceCount}} 个来源，查看更多</b-btn>
      </div>
    </template>
    <template v-if="step === 2">
      <b-card>
        <template #header>
          <ske width="8em" v-if="fetching && articlePage === 1"/>
          <b v-else>文章（共 {{articleCount || 0}} 篇）</b>
        </template>
        <template v-if="fetching && articlePage === 1">
          <div class="list">
            <div class="item-box" v-for="i in 7" :key="i">
              <ske width="1em"/>
              <ske width="10em"/>
              <ske width="6em" class="float-right"/>
            </div>
          </div>
        </template>
        <empty v-else-if="!articles.length">
          <template v-if="keywords">
            <div>这里没有任何与 <b>{{keywords}}</b> 相关内容哦</div>
            <div>换个词筛选试试吧</div>
          </template>
          <template v-else>
            这里还没有内容哦
          </template>
        </empty>
        <template v-else>
          <div class="list">
            <div class="item-box" v-for="item in articles" :key="item.id">
              <div class="article-item">
                <div class="article-number pointer" @click="selectArticle(item)">
                  <div>
                    <fa icon="check-circle"
                        :class="{'text-primary': selectedArticle.indexOf(item) > -1}"
                        v-if="selectedArticle.indexOf(item) > -1"/>
                    <fa icon="circle" far class="text-muted" v-else/>
                  </div>
                </div>
                <div class="article-title pointer" @click="selectArticle(item)">{{item.title}}</div>
                <div class="small text-overflow text-muted">
                  <fa icon="clock" fw/>
                  {{item.postDate | moment('YYYY.MM.DD')}}
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-card>
      <div class="btn-area" v-if="articles.length < articleCount">
        <loading v-if="fetching && articlePage !== 1" style="height: 39px;"/>
        <b-btn block @click="nextArticle" v-else>共 {{articleCount}} 篇文章，查看更多</b-btn>
      </div>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'

export default {
  name: 'blogbookCreation',
  title: '导入我的作品',
  mixins: [routeData('book')],
  data() {
    return {
      step: 1,
      sourceSite: {
        weixinmp: {
          name: '公众号',
          icon: 'https://img.xinshu.me/upload/519702402be14d2584247d11fabacd1f'
        },
        meipian: {
          name: '美篇',
          icon: 'https://img.xinshu.me/upload/d389bb80e1b54a47acd1d96f14b1e3d3'
        },
        jianshu: {
          name: '简书',
          icon: 'https://img.xinshu.me/upload/d072b8d762b64db0bec864a3e933af32'
        },
        sina_blog: {
          name: '新浪博客',
          icon: 'https://img.xinshu.me/upload/d68d7f8246554912ac38da5226ffc5bd'
        },
        sohu_blog: {
          name: '搜狐博客',
          icon: 'https://img.xinshu.me/upload/0cbadd0c492d4fac8ba797f09a486144'
        },
        zhihu_blog: {
          name: '知乎专栏',
          icon: 'https://img.xinshu.me/upload/94ec7aecf48e4c79b0eb44f892656ce8'
        },
        douban: {
          name: '豆瓣日记',
          icon: 'https://img.xinshu.me/upload/6689d7ef905c432bb713abb364fe2ea8'
        },
        lofter: {
          name: '乐乎',
          icon: 'https://img.xinshu.me/upload/3200849c829f406fb33b3200440988ce'
        },
        xinshu: {
          name: '原创',
          icon: 'https://img.xinshu.me/upload/ed6c600f27d04df1bf513fbff23ab226'
        },
        qq_blog: {
          name: 'QQ空间日志',
          icon: 'https://img.xinshu.me/upload/69f96960d78b4921bdb0ee502c63e9ba'
        }
      },
      original: {},
      sources: [],
      sourcePage: 1,
      sourceCount: 0,
      articles: [],
      articlePage: 1,
      articleCount: 0,
      fetching: false,
      selectedSource: [],
      selectedArticle: [],
      sourceId: '',
      searching: false,
      keywords: ''
    }
  },
  mounted() {
    this.getSource()
  },
  methods: {
    handleSearch() {
      if (this.keywords === '') {
        return
      }
      if (this.searching === false) {
        return
      }
      this.articles = []
      this.articlePage = 1
      this.articleCount = 0
      this.getArticles()
    },
    cancelSearch() {
      this.searching = false
      this.keywords = ''
      this.articles = []
      this.articlePage = 1
      this.articleCount = 0
      this.getArticles()
    },
    back() {
      this.step = 1
    },
    selectArticle(item) {
      if (this.selectedArticle.indexOf(item) === -1) {
        this.selectedArticle = this.selectedArticle.concat(item)
      } else {
        this.selectedArticle = this.selectedArticle.filter(i => i !== item)
      }
    },
    cancelSelect() {
      this.selectedSource = []
      this.selectedArticle = []
    },
    goDetail(item) {
      this.step = 2
      this.articles = []
      this.selectedArticle = []
      this.articlePage = 1
      this.articleCount = 0
      this.sourceId = item.id
      this.getArticles()
    },
    selectSource(item) {
      if (this.selectedSource.indexOf(item) === -1) {
        this.selectedSource = this.selectedSource.concat(item)
      } else {
        this.selectedSource = this.selectedSource.filter(i => i !== item)
      }
    },
    nextSource() {
      if ((this.sources.length + 1) >= this.sourceCount) {
        return
      }
      this.sourcePage++
      this.getSource()
    },
    nextArticle() {
      if (this.articles.length >= this.articleCount) {
        return
      }
      this.articlePage++
      this.getArticles()
    },
    async getSource() {
      this.fetching = true
      try {
        const res = await this.$req.get(`/blogbook/databases/?page=${this.sourcePage}&page_size=50`)
        this.sources = this.sources.concat(res)
        this.sourceCount = res.totalCount
      } catch (error) {
      } finally {
        this.fetching = false
      }
    },
    async getArticles() {
      this.fetching = true
      try {
        const res = await this.$req.get(`/blogbook/databases/${this.sourceId}/articles/?page=${this.articlePage}&page_size=50&title=${this.keywords}`)
        this.articles = this.articles.concat(res)
        this.articleCount = res.totalCount
      } catch (error) {
      } finally {
        this.fetching = false
      }
    },
    async importSource(val) {
      const confirmed = await this.$dialog.confirm({
        title: '导入作品',
        content: `确定将选择的内容导入到《${this.book.title}》吗？`,
        okTitle: '确定',
        cancelTitle: '取消'
      })
      if (confirmed) {
        try {
          let sids = []
          let aids = []
          if (val === 'source') {
            sids = this.selectedSource.map(item => item.id)
          } else {
            aids = this.selectedArticle.map(item => item.id)
          }
          await this.$req.post(`/blogbook/books/${this.book.id}/import/`, {
            sids: sids,
            aids: aids
          })
          this.cancelSelect()
          const goShelt = await this.$dialog.confirm({
            title: '导入作品',
            content: '导入成功，请到书架查看(导入文章可能有所延迟，请耐心等待)',
            okTitle: '前往书架',
            cancelTitle: '关闭'
          })
          if (goShelt) {
            this.$router.push('/books')
          }
        } catch (error) {
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.creation {
  max-width: 640px;
  position: relative;

  .select-bar {
    @include clearfix();
    margin-bottom: 1em;

    &.headroom {
      z-index: 20;
      top: 0;
      border-top: 0;
      width: 100%;
      max-width: 620px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include media-breakpoint-down(sm) {
        left: 0;
      }

      ~ .card {
        margin-top: 72px;
      }
    }
  }

  .item-box {
    display: block;
    padding: 1rem $card-spacer-x;
    border-bottom: 1px solid $hr-border-color;
    position: relative;

    .article-item {
      align-items: center;
      display: flex;

      > div:nth-child(3) {
        flex: 1;
      }

      > div:nth-child(1) {
        padding-right: 0.4rem;
      }

      > :nth-child(2) {
        flex: 5;
      }

      > div:nth-child(3) {
        text-align: right;
        flex: 3;
      }

      .article-title {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $body-color;
      }

      .goin {
        margin-left: 12px;
        font-size: 12px;
      }

      .icon {
        height: 1.25em;
        vertical-align: sub;
        width: 1.25em;
        filter: grayscale(1);
      }
    }
  }

  .card {
    overflow: hidden;
  }

  .list {
    margin: -$card-spacer-x;
  }

  .article-number {
    min-width: 1.75em;
    color: $text-muted;
  }

  .pointer {
    cursor: pointer;
  }
}
</style>
